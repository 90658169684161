import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { UtilsService } from "src/app/shared/services/utils.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import Swal from "sweetalert2";
import { ThemeService } from "src/app/shared/services/theme.service";

@Component({
  selector: "app-nav-left",
  templateUrl: "./nav-left.component.html",
  styleUrls: ["./nav-left.component.scss"],
})
export class NavLeftComponent implements OnInit {
  @ViewChild("searchInput") searchElement: ElementRef;
  entities = [];
  floors = [];
  search: string = "";
  data: any;

  constructor(
    private utils: UtilsService,
    private route: Router,
    private themeService: ThemeService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() { }

  loadData() {
    this.spinner.show("search");
    this.data = [];
    if (this.search == "") {
      Swal.fire({
        title: "Atenção",
        text: "Informe uma unidade ou andar para a busca.",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2000,
      });
      this.spinner.hide("search");
      return;
    }
    this.utils.get(`Entities/QuickSearch?word=${this.search}`).subscribe(
      res => {
        this.data = res["content"]["data"];
        this.data.forEach(entity => {
          entity.entity.name = entity.entity.name.toLowerCase();
          this.entities.push(entity.entity);
          entity.floors.forEach(element => {
            element.name = element.name.toLowerCase();
            this.floors.push(element);
          });
        });

        this.floors = [];
        this.searchElement.nativeElement.focus();
        this.spinner.hide("search");
      },
      err => {
        this.spinner.hide();
        console.error(err);
        if (err.status == 401) {
          Swal.fire({
            title: "Atenção",
            text: err.error.messages[0],
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else if (err.status == 400) {
          Swal.fire({
            title: "Erro",
            text: err.error.messages[0],
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            title: "Erro",
            text: "Ops, não foi possível obter conexão com o servidor.",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
          });
        }
      }
    );
  }

  go(data) {
    this.data = [];
    this.search = "";
    if (data[1] === true) {
      this.route.navigate(["dashboard/entity"], {
        queryParams: { id: data[0] },
      });
      setTimeout(() => {
        location.reload();
      }, 700);
    } else {
      this.route.navigate(["dashboard/floor"], {
        queryParams: { id: data[0] },
      });
      setTimeout(() => {
        location.reload();
      }, 700);
    }
  }

  get backgroundColor() {
    return this.themeService.sessionPrimaryColor || this.themeService.defaultPrimaryColor;
  }
}
