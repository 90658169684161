<header>
  <div class="m-header" [style.display]="this.headerStyle" [style.background-color]="backgroundColor">
    <a class="sidebar-wrapper ps ps--theme_default ps--active-x ps--active-y" id="mobile-collapse1" href="javascript:"
      (click)="this.onNavCollapsedMob.emit()" sty><span></span></a>
    <a [routerLink]="['/dashboard/management']" class="b-brand">
      <span class="b-title">
        <img [src]="themeService.sessionLogoImg" class="logo" alt="" /></span>
    </a>
  </div>
  <a class="mobile-menu" [ngClass]="{ on: this.menuClass }" id="mobile-header" href="javascript:"
    (click)="toggleMobOption()"><i class="feather icon-more-horizontal"></i></a>
  <div class="collapse navbar-collapse menu" [style.background-color]="backgroundColor" [ngClass]="headerWidth">
    <app-nav-left class="w-50" [style.display]="this.headerStyle"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</header>