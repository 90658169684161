import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() iconClass = "";
  @Input() className = "";
  @Output() onClick = new EventEmitter();

  constructor() { }

  applyCss() {
    return `${this.iconClass}`
  }

  emitOnClick() {
    if (this.onClick) {
      this.onClick.emit();
    }
  }
}
