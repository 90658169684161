import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { ThemeService } from '../../services/theme.service';

export const ButtonSizes = {
  sm: 'sm',
  xmd: 'xmd',
  md: 'md',
  lg: 'lg',
};

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text = '';
  @Input() title = '';
  @Input() tooltip = '';
  @Input() isMatButton = false;
  @Input() size = 'lg';
  @Input() textCss = '';
  @Input() permission = '';
  @Input() customStyle = '';
  @Input() className = 'btn-primary';
  @Input() isDisabled = false;
  @Output() onClick = new EventEmitter();

  constructor(
    private _utils: UtilsService,
    public themeService: ThemeService
  ) { }

  emitEvent() {
    this.onClick.emit();
  }

  applyCss() {
    return `${this.size} ${this.className}`;
  }

  get hasPermission(): boolean {
    return this._utils.hasPermission(this._utils.permissionsLocalStorage, this.permission);
  }

  get buttonTitle(): string {
    return this.permission ? this.titlePermission : this.title;
  }

  get titlePermission(): string {
    return this.hasPermission ? this.title : "Usuário sem permissão para acessar esse item.";
  }

  get disabled(): boolean {
    return this.permission ? this.isDisabled || !this.hasPermission : this.isDisabled;
  }

  get backgroundColor(): string {
    const sessionPrimaryColor = this.themeService.sessionPrimaryColor
    const isPrimary = this.className.includes("btn-primary");
    return isPrimary && sessionPrimaryColor ? sessionPrimaryColor : "";
  }
}
