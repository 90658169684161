import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FeatureService {
    private readonly configs: any;

    constructor(private http: HttpClient){
        this.configs = {}
    }

    carregaJson(): Promise<any> {
        return this.http
          .get(`assets/config.json`)
          .toPromise();
    }

    public async init(): Promise<any>{
        const configs = await this.carregaJson()
        this.setConfigs(configs)
    }

    get(key: string){
        return this.configs[key]
    }

    getAll() {
       return this.configs;
    }
    
    private setConfigs<T>(configs: T) {
        Object.keys(configs).forEach((key: string) => {
            this.configs[key] = configs[key as keyof T];
        });
    }
}