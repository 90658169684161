import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { DocumentsComponent } from './views/pages/documents/documents.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./views/pages/authentication/authentication.module').then(
            m => m.AuthenticationModule
          )
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            m => m.DashboardModule
          )
      }
    ]
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    loadChildren: () =>
      import('./views/pages/documents/documents.module').then(
        m => m.DocumentsModule
      )
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    loadChildren: () =>
      import('./views/pages/forgot-password/forgot-password.module').then(
        m => m.FogotPasswordModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
