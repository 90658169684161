<div [ngClass]="themeService.theme">
    <button type="button" class="btn-default" [matTooltip]="tooltip" [ngClass]="applyCss()" 
    [title]="buttonTitle"
        [style.background-color]="backgroundColor" [ngStyle]="customStyle" (click)="emitEvent()" [disabled]="disabled">
        <ng-content select=".txt-before"></ng-content>
        <ng-content select=".btn-icon"></ng-content>
        <p class="p-0 m-0" [ngClass]="textCss"> {{ text }} </p>
        <ng-content select=".btn-icon-right"></ng-content>
    </button>
</div>
