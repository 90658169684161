import { Component, DoCheck, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ThemeService } from './shared/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
  title = '';
  themeColor: string;

  constructor(
    private router: Router,
    private themeService: ThemeService
  ) { }

  ngDoCheck(): void {
    this.themeService.setPaginationComponentColor();
    this.themeService.setTableHoverColor();
    this.themeService.setShepherdButtonColor();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


}
