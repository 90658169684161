import { Injectable } from "@angular/core";

export interface NavigationItemInterface {
  id: string;
  title: string;
  type: "item" | "collapse" | "group";
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  disabled?: boolean;
  children?: NavigationItemInterface[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItemInterface[];
}

const NavigationItems: NavigationItemInterface[] = [
  {
    id: "navigation",
    title: "",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Gerencial",
        type: "item",
        url: "/dashboard/management",
        icon: "feather icon-home",
        classes: "nav-item",
        breadcrumbs: false,
      },
      {
        id: "menu-serviços",
        title: "Serviços",
        type: "group",
        icon: "feather icon-edit",
        children: [
          {
            id: "request",
            title: "Solicitações de Serviço",
            type: "item",
            url: "/dashboard/service-request",
            icon: "feather icon-file-minus",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "order",
            title: "Ordens",
            type: "item",
            url: "/dashboard/order",
            icon: "feather icon-tablet",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "measurements",
            title: "Medições",
            type: "item",
            url: "/dashboard/measurements",
            icon: "feather icon-file-text",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "prints",
            title: "Relatórios",
            type: "item",
            url: "/dashboard/prints",
            icon: "feather icon-download",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "menu-geral",
        title: "Geral",
        type: "collapse",
        icon: "feather icon-edit",
        children: [
          {
            id: "contract",
            title: "Contratos",
            type: "item",
            url: "/dashboard/contract",
            icon: "feather icon-clipboard",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "unidade",
            title: "Unidades",
            type: "item",
            url: "/dashboard/entity",
            icon: "feather icon-book",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "floor",
            title: "Andares",
            type: "item",
            url: "/dashboard/floor",
            icon: "feather icon-layers",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "sector",
            title: "Setores",
            type: "item",
            url: "/dashboard/sector",
            icon: "feather icon-grid",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "local",
            title: "Locais",
            type: "item",
            url: "/dashboard/service",
            icon: "feather icon-briefcase",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "region",
            title: "Regiões",
            type: "item",
            url: "/dashboard/region",
            icon: "feather icon-map-pin",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "menu-colaboradores",
        title: "Colaboradores",
        type: "collapse",
        icon: "feather icon-user",
        children: [
          {
            id: "account",
            title: "Usuários",
            type: "item",
            url: "/dashboard/account",
            icon: "feather icon-user",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "team",
            title: "Equipes",
            type: "item",
            url: "/dashboard/team",
            icon: "feather icon-user-plus",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "menu-ponto",
        title: "Jornada de trabalho",
        type: "collapse",
        icon: "feather icon-calendar",
        children: [
          {
            id: "jobTitles",
            title: "Cargos",
            type: "item",
            url: "/dashboard/job-titles",
            icon: "feather icon-bookmark",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "shift",
            title: "Escalas",
            type: "item",
            url: "/dashboard/shift",
            icon: "feather icon-calendar",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "due-vacation",
            title: "Afastamentos e Férias",
            type: "item",
            url: "/dashboard/due-vacation",
            icon: "feather icon-file-minus",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "pointControl",
            title: "Controle de Pontos",
            type: "item",
            url: "/dashboard/point-control",
            icon: "feather icon-calendar",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "holiday",
            title: "Feriados e Recessos",
            type: "item",
            url: "/dashboard/holiday",
            icon: "feather icon-shield",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "solicitation",
            title: "Solicitações",
            type: "item",
            url: "/dashboard/solicitation",
            icon: "feather icon-clipboard",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "menu-equipamentos",
        title: "Ativos",
        type: "collapse",
        icon: "feather icon-activity",
        children: [
          {
            id: "formTypes",
            title: "Tipos de Ativos",
            type: "item",
            url: "/dashboard/equipment-type",
            icon: "feather icon-file-plus",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "formTypes",
            title: "Ativos",
            type: "item",
            url: "/dashboard/equipment",
            icon: "feather icon-activity",
            classes: "nav-item",
            breadcrumbs: false,
          }
        ]
      },
      {
        id: "menu-manutenção",
        title: "Manutenções",
        type: "collapse",
        icon: "feather icon-settings",
        children: [
          {
            id: "formTypes",
            title: "Planos de Manutenção",
            type: "item",
            url: "/dashboard/formType",
            icon: "feather icon-list",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "form",
            title: "Formulários",
            type: "item",
            url: "/dashboard/form",
            icon: "feather icon-align-justify",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "budgetsource",
            title: "Fontes Orçamentárias",
            type: "item",
            url: "/dashboard/budget-source",
            icon: "feather icon-activity",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "unitCost",
            title: "Custos Unitários",
            type: "item",
            url: "/dashboard/unit-cost",
            icon: "fas fa-coins",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "unitCostComposition",
            title: "Composição de Custos Unitários",
            type: "item",
            url: "/dashboard/unit-cost-composition",
            icon: "feather icon-bar-chart-2",
            classes: "nav-item",
            breadcrumbs: false,
          }
        ]
      },
      {
        id: "menu-dispositivos",
        title: "Dispositivos",
        type: "collapse",
        icon: "feather icon-cpu",
        children: [
          {
            id: "microControllers",
            title: "Microcontroladores",
            type: "item",
            url: "/dashboard/micro-controllers",
            icon: "feather icon-cpu",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "device-type",
            title: "Tipo de Dispositivo",
            type: "item",
            url: "/dashboard/device-type",
            icon: "feather icon-tablet",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "devices",
            title: "Dispositivos",
            type: "item",
            url: "/dashboard/devices",
            icon: "feather icon-cast",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "menu-controle",
        title: "Controle",
        type: "collapse",
        icon: "feather icon-sliders",
        children: [
          {
            id: "permissions",
            title: "Permissões",
            type: "item",
            url: "/dashboard/permission",
            icon: "feather icon-shield",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "parameters",
            title: "Parâmetros de Controle",
            type: "item",
            url: "/dashboard/control-parameters",
            icon: "feather icon-sliders",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "system",
            title: "Sistemas",
            type: "item",
            url: "/dashboard/system",
            icon: "feather icon-film",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "message-template",
            title: "Modelos de mensagem",
            type: "item",
            url: "/dashboard/message-template",
            icon: "feather icon-message-square",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "print-configuration",
            title: "Config. de Impressão",
            type: "item",
            url: "/dashboard/print-configuration",
            icon: "feather icon-printer",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "app-settings",
            title: "Config. do Sistema",
            type: "item",
            url: "/dashboard/app-settings",
            icon: "feather icon-settings",
            classes: "nav-item",
            breadcrumbs: false,
          },

        ]
      },
      {
        id: "menu-audit-alert",
        title: "Auditoria e Alertas",
        type: "collapse",
        icon: "feather icon-alert-triangle",
        children: [
          {
            id: "audit-alerts-logs",
            title: "Painel de Controle",
            type: "item",
            url: "/dashboard/alert-logs",
            icon: "feather icon-sliders",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "audit-alerts-logs",
            title: "Alertas",
            type: "item",
            url: "/dashboard/audit-alerts",
            icon: "feather icon-alert-triangle",
            classes: "nav-item",
            breadcrumbs: false,
          }
        ]
      },
      {
        id: "menu-localizações",
        title: "Localizações",
        type: "collapse",
        icon: "feather icon-map-pin",
        children: [
          {
            id: "alert",
            title: "Hist. de Localizações",
            type: "item",
            url: "/dashboard/alert",
            icon: "feather icon-alert-triangle",
            classes: "nav-item mr-1",
            breadcrumbs: false,
          },
          {
            id: "map",
            title: "Mapa Geral",
            type: "item",
            url: "/dashboard/map",
            icon: "feather icon-map",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "map",
            title: "Mapa de Calor",
            type: "item",
            url: "/dashboard/map/heat-map",
            icon: "feather icon-map",
            classes: "nav-item",
            breadcrumbs: false,
          },
          {
            id: "log-analysis",
            title: "Logs",
            type: "item",
            url: "/dashboard/log-analysis",
            icon: "feather icon-bar-chart-2",
            classes: "nav-item",
            breadcrumbs: false,
          }
        ]
      },
      {
        id: "menu-concierge",
        title: "Portarias",
        type: "collapse",
        icon: "feather icon-edit",
        children: [
          {
            id: "alert",
            title: "Portarias",
            type: "item",
            url: "/dashboard/concierge",
            icon: "feather icon-grid",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      },
      {
        id: "pages",
        title: "Funcionalidades",
        type: "group",
        icon: "icon-pages",
        children: [
          {
            id: "signin",
            title: "Sair",
            type: "item",
            url: "/auth/signin",
            icon: "feather icon-lock",
            classes: "nav-item",
            breadcrumbs: false,
          },
        ]
      }
    ]
  }
]

const fiscalDisableditens = [
  "measurements",
  "prints",
]

const fiscalDisabledGroup = [
  "menu-geral",
  "menu-colaboradores",
  "menu-ponto",
  "menu-equipamentos",
  "menu-manutenção",
  "menu-dispositivos",
  "menu-controle",
  "menu-localizações"
]

const operationalDisabledItens = [
  "form",
  "microControllers",
  "print-configuration",
  "app-settings",
  "alert",
  "log-analysis"
]

const operationalDisabledGroup = []

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }

  get fiscalDisableditens() {
    return fiscalDisableditens;
  }

  get fiscalDisabledGroup() {
    return fiscalDisabledGroup;
  }

  get operationalDisabledGroup() {
    return operationalDisabledGroup;
  }

  get operationalDisabledItens() {
    return operationalDisabledItens;
  }
}
