<div class="d-flex justify-content-end">
  <div class="entity-dpd" [ngClass]="themeService.theme">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <div class="d-flex justify-content-center align-items-center mt-2 entity">
        <i class="fas fa-university fa-lg pr-3"></i>
        <span class="pr-3 name">{{entityName}}</span>
        <i [class]="toggleIcon" ngbDropdownToggle data-toggle="dropdown" (click)="toggleShowIcon()"></i>
      </div>
      <div class="mt-2 dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="cardBody">
          <div class="my-3 text-center">
            <p class="font-weight-bold"> Contratante Selecionado: </p>
            <p> {{ entityName }} </p>
          </div>
          <div *ngFor="let entity of userEntities; let idx = index" class="my-2">
            <hr />
            <p class="pl-3 item-hover" id="item" (click)="setTenantId(entity.id, entity.name)">
              {{ idx + 1 }}. {{ entity.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ul class="navbar-nav" [ngClass]="themeService.theme">
    <li class="pr-1">
      <div class="dropdown drp-user" ngbDropdown placement="auto">
        <a class="navbar-icon" (click)="clearNotifications()" [matBadge]="notificationCount"
          [matBadgeHidden]="!matBadgeShow" matBadgeColor="warn" href="javascript:" ngbDropdownToggle
          data-toggle="dropdown">
          <i class="far fa-bell fa-lg f22"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
          <div class="noti-body">
            <div class="notification" *ngFor="let notification of data; let idx = index">
              <a href="javascript:" (click)="loadPage(notification.status)">
                <div class="media">
                  <i class="fas fa-bell fa-lg pr-2" [style]="iconColor"></i>
                  <div class="media-body">
                    <p>{{ notification.message }}</p>
                  </div>
                </div>
                <hr class="m-0 p-0">
              </a>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div class="dropdown drp-user" ngbDropdown placement="auto">
        <a class="navbar-icon" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
          <img src="assets/images/user/avatar-4.jpg" class="imgUser" alt="User-Profile-Image" />
        </a>
        <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
          <ul class="pro-body">
            <li>
              <a href="javascript:" class="dropdown-item" (click)="openPerfil(true)">
                <i class="fas fa-user fa-lg" [style]="iconColor"></i> Perfil
              </a>
            </li>
            <li>
              <a href="javascript:" class="dropdown-item" (click)="openAlertLogs()">
                <i class="fas fa-desktop fa-lg" [style]="iconColor"></i> Painel de Controle
              </a>
            </li>
            <li>
              <a href="javascript:" class="dropdown-item" (click)="openPerfil()">
                <i class="fas fa-cog fa-lg" [style]="iconColor"></i> Configurações
              </a>
            </li>
            <hr>
            <li>
              <a href="javascript:" class="dropdown-item" (click)="logout()">
                <i class="fas fa-sign-in-alt fa-lg" [style]="iconColor"></i> Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</div>