import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent implements OnInit {
  @Input() navCollapsed: boolean;
  @Output() onNavCollapse = new EventEmitter();
  public windowWidth: number;
  openLogo = true;

  constructor(
    public themeService: ThemeService
  ) {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
  }

  navCollapse() {
    this.openLogo = !this.openLogo;
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.onNavCollapse.emit();
    }
  }

  get backgroundColor() {
    return this.themeService.sessionPrimaryColor || this.themeService.defaultPrimaryColor;
  }

  get menuIcon() {
    return this.openLogo ?
      "fas fa-chevron-left menuIcon" :
      "fas fa-chevron-right menuIcon";
  }

  get iconTitle() {
    return this.openLogo ?
      "Minimizar menu" :
      "Expandir menu";
  }

}
