<div class="documents">
    <div class="auth-wrapper">
        <div class="content">
            <mat-card class="overflow-auto">
                <mat-card-content class="card-content">
                    <p class="my-4 text-center h5">
                        <b>
                            TERMO DE USO E POLITICA DE PRIVACIDADE
                        </b>
                    </p>
                    <p class="my-4 text-center h6">
                        <b>Termos e Condições Gerais de Uso do aplicativo Point Service </b>
                    </p>
                    <p>
                        Os serviços do Point Service são fornecidos pela pessoa jurídica de seguinte Razão Social/nome
                        FAB
                        SOLUCOES LTDA, com nome fantasia FAB SOLUÇÕES, inscrito no CNPJ/CPF sob o nº 37.845.648/0001-79,
                        titular da propriedade intelectual sobre software, website, aplicativos, conteúdos e demais
                        ativos
                        relacionados à plataforma Point Service
                    </p>
                    <p> <b>1. Do objeto</b> </p>
                    <p>
                        A plataforma visa licenciar o uso de seu aplicativo e demais ativos de propriedade intelectual,
                        fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários.
                        A plataforma caracteriza-se pela prestação dos seguintes serviços: Geolocalização e
                        Gerenciamento de
                        Serviços.
                    </p>
                    <p> <b>2. Da aceitação</b> </p>
                    <p>
                        O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo
                        indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do aplicativo.
                        Ao utilizar a plataforma, o usuário aceita integralmente as presentes normas e compromete-se a
                        observá-las, sob o risco de aplicação das penalidades cabíveis.
                        A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de
                        quaisquer
                        serviços fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o
                        usuário
                        não deve utilizá-los.
                    </p>
                    <p><b>3. Do acesso dos usuários</b></p>
                    <p>
                        Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para
                        permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No
                        entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida,
                        limitada ou
                        suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.
                        (Se há necessidade de cadastro para uso da plataforma)
                    </p>
                    <p><b>4. Do cadastro</b></p>
                    <p>
                        O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a
                        depender
                        dos serviços ou produtos escolhidos, o pagamento de determinado valor.
                    </p>
                    <p>
                        Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos,
                        sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem
                        como o usuário se compromete com a veracidade dos dados fornecidos.
                        O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à
                        plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja
                        feito.
                    </p>
                    <p>
                        Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão
                        obter previamente o consentimento expresso de seus responsáveis legais para
                        utilização da plataforma e dos serviços ou produtos, sendo de responsabilidade
                        exclusiva dos mesmos o eventual acesso por menores de idade e por aqueles que
                        não possuem plena capacidade civil sem a prévia autorização.
                        Mediante a realização do cadastro o usuário declara e garante expressamente ser
                        plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.
                        O usuário deverá fornecer um endereço de e-mail válido, através do qual o site
                        realizará todas as comunicações necessárias.
                    </p>
                    <p>
                        Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal,
                        a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao
                        usuário exclusivamente a manutenção de referida senha de maneira confidencial e
                        segura, evitando o acesso indevido às informações pessoais.
                        Toda e qualquer atividade realizada com o uso da senha será de responsabilidade
                        do usuário, que deverá informar prontamente a plataforma em caso de uso indevido
                        da respectiva senha.
                        Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta,
                        que é pessoal e intransferível.
                        Caberá ao usuário assegurar que o seu equipamento seja compatível com as
                        características técnicas que viabilize a utilização da plataforma e dos serviços ou
                        produtos.
                    </p>
                    <p>
                        O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto
                        ao aplicativo Point Service. O seu descadastramento será realizado o mais
                        rapidamente possível, desde que não sejam verificados débitos em aberto.
                        O usuário, ao aceitar os Termos e Política de Privacidade, autoriza expressamente a
                        plataforma a coletar, usar, armazenar, tratar, ceder ou utilizar as informações
                        derivadas do uso dos serviços, do site e quaisquer plataformas, incluindo todas as
                        informações preenchidas pelo usuário no momento em que realizar ou atualizar seu
                        cadastro, além de outras expressamente descritas na Política de Privacidade que
                        deverá ser autorizada pelo usuário.
                    </p>
                    <p>
                        O usuário, ao aceitar as condições de uso, está ciente de que o aplicativo Point Service
                        utiliza dados de localização, bluethoot, câmera, envio de notificações, podendo ser
                        atualizado em segundo plano, com a coleta de dados celulares, desde que o usuário
                        assim permita.
                    </p>
                    <p><b> 5. Dos serviços ou produtos </b></p>
                    <p>
                        A plataforma poderá disponibilizar para o usuário um conjunto específico de
                        funcionalidades e ferramentas para otimizar o uso dos serviços e produtos.
                        Na plataforma, sempre que oportuno, os serviços ou produtos oferecidos estão
                        descritos e apresentados com o maior grau de exatidão, contendo informações sobre
                        suas características, qualidades, quantidades, composição, preço, garantia, prazos
                        de validade e origem, entre outros dados, bem como sobre os riscos que apresentam
                        à saúde e segurança do usuário.
                    </p>
                    <p><b> 6. Do suporte </b></p>
                    <p>
                        Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma,
                        o usuário poderá entrar em contato com o suporte, através do email
                        servicedesk@ito1.com.br.
                    </p>
                    <p><b> 7. Das responsabilidades </b></p>
                    <p>
                        É de responsabilidade do usuário:
                    <p>
                        a) defeitos ou vícios técnicos originados no próprio sistema do usuário;
                    </p>
                    <p>
                        b) a correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando
                        pela boa convivência, pelo respeito e cordialidade entre os usuários;
                    </p>
                    <p>
                        c) pelo cumprimento e respeito ao conjunto de regras dispostas neste Termo de
                        Condições Geral de Uso, na respectiva Política de Privacidade e na legislação
                        nacional e internacional;
                    </p>
                    <p>
                        d) pela proteção aos dados de acesso à sua conta/perfil (login e senha).
                        É de responsabilidade da plataforma Point Service:
                    </p>
                    <p>
                        a) indicar as características do serviço ou produto;
                    </p>
                    <p>
                        b) os defeitos e vícios encontrados no serviço ou produto oferecido desde que lhe
                        tenha dado causa;
                    </p>
                    <p>
                        c) as informações que foram por ele divulgadas, sendo que os comentários ou
                        informações divulgadas por usuários são de inteira responsabilidade dos próprios
                        usuários;
                    </p>
                    <p>
                        d) os conteúdos ou atividades ilícitas praticadas através da sua plataforma.
                        A plataforma não se responsabiliza por links externos contidos em seu sistema que
                        possam redirecionar o usuário à ambiente externo a sua rede.
                    </p>
                    <p>
                        Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais
                        ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas,
                        pornográficas, xenofóbicas, discriminatórias ou ofensivas.
                    </p>
                    <p><b> 08. Dos direitos autorais </b> </p>
                    <p>
                        O presente Termo de Uso concede aos usuários uma licença não exclusiva, não
                        transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos
                        serviços e produtos por ela disponibilizados.
                    </p>
                    <p>
                        A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts,
                        gráficos e design de interface, imagens, ilustrações, fotografias, apresentações,
                        vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de
                        dados, arquivos de transmissão e quaisquer outras informações e direitos de
                        propriedade intelectual da razão social FAB SOLUCOES LTDA, observados os termos
                        da <i>Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei
                            nº 9.610/98) e Lei do Software (Lei nº 9.609/98) </i>, estão devidamente reservados.
                        Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que
                        o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto
                        pela licença limitada ora concedida.
                    </p>
                    <p>
                        O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado
                        qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em
                        violação dos direitos de propriedade intelectual da FAB SOLUCOES LTDA, puníveis
                        nos termos da legislação aplicável.
                    </p>
                    <p><b>09. Das sanções </b></p>
                    <p>
                        Sem prejuízo das demais medidas legais cabíveis, a FAB SOLUCOES LTDA poderá, a
                        qualquer momento, advertir, suspender ou cancelar a conta do usuário:
                    </p>
                    <p>
                        a) que violar qualquer dispositivo do presente Termo;
                    </p>
                    <p>
                        b) que descumprir os seus deveres de usuário;
                    </p>
                    <p>
                        c) que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.
                    </p>
                    <p><b> 10. Da rescisão </b></p>
                    <p>
                        A não observância das obrigações pactuadas neste Termo de Uso ou da legislação
                        aplicável poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte
                        da FAB SOLUCOES LTDA e o bloqueio de todos os serviços prestados ao usuário.
                    </p>
                    <p><b> 11. Das alterações </b></p>
                    <p>
                        Os itens descritos no presente instrumento poderão sofrer alterações,
                        unilateralmente e a qualquer tempo, por parte da FAB SOLUCOES LTDA, para
                        adequar ou modificar os serviços, bem como para atender novas exigências legais.
                        As alterações serão veiculadas pelo aplicativo Point Service e o usuário poderá
                        optar por aceitar o novo conteúdo.
                    </p>
                    <p><b>12. Da política de privacidade</b></p>
                    <p>Além do presente Termo, o usuário deverá consentir com as disposições contidas na
                        respectiva Política de Privacidade a ser apresentada a todos os interessados dentro
                        da interface da plataforma.
                    </p>
                    <p><b> 13. Do foro </b></p>
                    <p>
                        Para a solução de controvérsias decorrentes do presente instrumento, será aplicado
                        integralmente o Direito brasileiro.
                        Os eventuais litígios deverão ser apresentados no foro da comarca de São Paulo.
                    </p>
                    <p class="my-4 text-center h6">
                        <b>Política de privacidade</b>
                    </p>
                    <p><b>SEÇÃO 1 - INFORMAÇÕES GERAIS</b></p>
                    <p>
                        A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento,
                        tratamento e proteção dos dados pessoais dos usuários e visitantes do aplicativo e site
                        Point Service, com a finalidade de demonstrar absoluta transparência quanto ao assunto e
                        esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da
                        coleta e a forma como os usuários podem gerenciar ou excluir as suas informações
                        pessoais.
                    </p>
                    <p>
                        Esta Política de Privacidade aplica-se a todos os usuários e visitantes do aplicativo e site
                        Point Service e integra os Termos e Condições Gerais de Uso do aplicativo e site Point
                        Service, devidamente inscrita no CNPJ sob o nº 37.845.648/0001-79, situado em AV
                        JABAQUARA, 2958, SALA 95 - 9 ANDAR, doravante nominada FAB SOLUCOES LTDA.
                    </p>
                    <p>
                        O presente documento foi elaborado em conformidade com a <i> Lei Geral de Proteção de
                            Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento
                            da UE n. 2016/6790).</i> Ainda, o documento poderá ser atualizado em decorrência de
                        eventual atualização normativa, razão pela qual se convida o usuário a consultar
                        periodicamente esta seção.
                    </p>
                    <p>
                        <b>
                            SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
                            VISITANTE
                        </b>
                    </p>
                    <p>
                        Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma:
                        Quando o usuário cria uma conta/perfil na plataforma Point Service: esses dados são os
                        dados de identificação básicos, como nome, e-mail, documento, telefone. A partir deles,
                        podemos identificar o usuário e o visitante, além de garantir uma maior segurança e
                        bem-estar às suas necessidades. Ficam cientes os usuários e visitantes de que seu perfil
                        na plataforma estará acessível a todos demais usuários e visitantes da plataforma Point
                        Service.
                    </p>
                    <p>
                        Quando um usuário acessa páginas do site ou aplicativo Point Service: as informações
                        sobre sua localização são coletadas pela empresa para garantir uma melhor experiência ao
                        usuário.
                    </p>
                    <p>
                        <b>
                            SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE

                        </b>
                    </p>
                    <p>
                        Os dados pessoais do usuário e visitante recolhidos são os seguintes:
                        Dados para a criação da conta/perfil na plataforma Point Service: e-mail, nome completo,
                        telefone, documento.
                    </p>
                    <p>
                        <b>
                            SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO
                            E VISITANTE
                        </b>
                    </p>
                    <p>
                        Os dados pessoais do usuário e do visitante coletados e armazenados pela plataforma
                        Point Service tem por finalidade:
                    </p>
                    <p>
                        Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido, facilitar,
                        agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a
                        experiência dos usuários e fornecer funcionalidades específicas a depender das
                        características básicas do usuário.
                    </p>
                    <p>
                        Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da
                        plataforma, exclusivo para usuários cadastrados
                        O tratamento de dados pessoais para finalidades não previstas nesta Política de
                        Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os
                        direitos e obrigações aqui previstos permanecem aplicáveis.
                    </p>
                    <p><b>SEÇÃO 5 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS? </b></p>
                    <p>
                        Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o
                        período necessário para a prestação do serviço ou o cumprimento das finalidades previstas
                        no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.
                        Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos
                        em que a lei oferecer outro tratamento.
                    </p>
                    <p>
                        Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de
                        seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:
                        I - cumprimento de obrigação legal ou regulatória pelo controlador;
                    </p>
                    <p>
                        II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos
                        dados pessoais;
                    </p>
                    <p>
                        III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados
                        dispostos nesta Lei;
                    </p>
                    <p>
                        IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
                        anonimizados os dados.
                    </p>
                    <p><b>SEÇÃO 6 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</b></p>
                    <p>
                        A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger
                        os dados pessoais de acessos não autorizados e de situações de destruição, perda,
                        alteração, comunicação ou difusão de tais dados.
                    </p>
                    <p>
                        Os dados relativas a cartões de crédito são criptografados usando a tecnologia "secure
                        socket layer" (SSL) que garante a transmissão de dados de forma segura e confidencial, de
                        modo que a transmissão dos dados entre o servidor e o usuário ocorre de maneira cifrada e
                        encriptada.
                    </p>
                    <p>
                        A plataforma não se exime de responsabilidade por culpa exclusiva de terceiro, como em
                        caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em
                        que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o
                        usuário em caso de alguma violação de segurança dos seus dados pessoais.
                        Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites
                        legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados
                        pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
                    </p>
                    <p><b>SEÇÃO 7 - COMPARTILHAMENTO DOS DADOS</b></p>
                    <p>
                        O compartilhamento de dados do usuário ocorre apenas com os dados referentes a
                        publicações realizadas pelo próprio usuário, tais ações são compartilhadas publicamente
                        com os outros usuários.
                    </p>
                    <p>

                        Os dados do perfil do usuário são compartilhados publicamente em sistemas de busca e
                        dentro da plataforma, sendo permitido ao usuário modificar tal configuração para que seu
                        perfil não apareça nos resultados de busca de tais ferramentas.
                    </p>
                    <p>
                        <b>
                            SEÇÃO 8 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A
                            TERCEIROS
                        </b>
                    </p>
                    <p>
                        Os dados pessoais não podem ser compartilhados com terceiros.
                    </p>
                    <p>
                        Com relação aos fornecedores de serviços terceirizados como processadores de transação
                        de pagamento, informamos que cada qual tem sua própria política de privacidade. Desse
                        modo, recomendamos a leitura das suas políticas de privacidade para compreensão de
                        quais informações pessoais serão usadas por esses fornecedores.
                    </p>
                    <p>
                        Os fornecedores podem ser localizados ou possuir instalações localizadas em países
                        diferentes. Nessas condições, os dados pessoais transferidos podem se sujeitar às leis de
                        jurisdições nas quais o fornecedor de serviço ou suas instalações estão localizados.
                    </p>
                    <p>
                        Ao acessar nossos serviços e prover suas informações, você está consentindo o
                        processamento, transferência e armazenamento desta informação em outros países.
                        Ao ser redirecionado para um aplicativo ou site de terceiros, você não será mais regido por
                        essa Política de Privacidade ou pelos Termos de Serviço da nossa plataforma. Não somos
                        responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as
                        declarações de privacidade deles.
                    </p>
                    <p><b>SEÇÃO 9 - CONSENTIMENTO</b></p>
                    <p>
                        Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está
                        consentindo com a presente Política de Privacidade.
                    </p>
                    <p>
                        O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar
                        seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das
                        informações por ele disponibilizadas.
                    </p>
                    <p>
                        O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve
                        entrar em contato através do e-mail atendimento@ito1.com.br.
                    </p>
                    <p><b>SEÇÃO 10 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</b></p>
                    <p>
                        Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então,
                        é recomendável que o usuário e visitante revise-a com frequência.
                    </p>
                    <p>
                        As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na
                        plataforma. Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço
                        ou fornecer informações pessoais após eventuais modificações, o usuário e visitante
                        demonstra sua concordância com as novas normas.
                    </p>
                    <p>
                        Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser
                        transferidas para os novos proprietários para que a permanência dos serviços oferecidos.
                    </p>
                    <p><b>SEÇÃO 11 - JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</b></p>
                    <p>
                        Para a solução de controvérsias decorrentes do presente instrumento será aplicado
                        integralmente o Direito brasileiro.
                    </p>
                    <p>
                        Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a
                        sede da empresa.
                    </p>
                    <div class="d-flex justify-content-center pb-4">
                        <app-button text="Voltar" size="md" className="btn-secondary" (onClick)="goBack()"
                            title="Voltar">
                        </app-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>