import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { ThemeService } from '../../services/theme.service';

export const ButtonSizes = {
  sm: "sm",
  xmd: "xmd",
  md: "md",
  lg: "lg",
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() text = "";
  @Input() title = "";
  @Input() size = "lg";
  @Input() textCss = "";
  @Input() permission = "";
  @Input() customStyle = "";
  @Input() className = "btn-primary";
  @Input() isDisabled = false;
  @Output() onClick = new EventEmitter();

  constructor(
    private utils: UtilsService,
    private themeService: ThemeService
  ) { }

  emitEvent() {
    this.onClick.emit();
  }

  applyCss() {
    return `${this.size} ${this.className}`
  }

  get hasPermission() {
    return this.utils.hasPermission(this.utils.permissionsLocalStorage, this.permission);
  }

  get buttonTitle() {
    return this.permission ? this.titlePermission : this.title;
  }

  get titlePermission() {
    return this.hasPermission ? this.title : "Usuário sem permissão para acessar esse item.";
  }

  get disabled() {
    return this.permission ? this.isDisabled || !this.hasPermission : this.isDisabled;
  }

  get backgroundColor() {
    const sessionPrimaryColor = this.themeService.sessionPrimaryColor
    const isPrimary = this.className.includes("btn-primary");
    return isPrimary && sessionPrimaryColor ? sessionPrimaryColor : "";
  }

}
