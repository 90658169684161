<div class="auth-wrapper" [ngStyle]="{'background-color': themeConfig.themeColor}"
  [style.background-image]="'url(' + themeConfig.backgroundImg + ')'">
  <div class="auth-content">
    <div class="card">
      <div class="row align-items-center text-center card-h">
        <div class="col-md-12">
          <div class="card-body">
            <div>
              <img [src]="themeConfig.loginImg" class="logo mg-fluid mb-4" alt="">
            </div>
              <div>
              <p>Esqueceu sua senha?</p>
              <h3>Digite seu cpf que vamos te ajudar</h3>
            </div>
            <div>
              <app-input label="CPF" matSuffixIcon="account_circle" inputType="text" [(modelNg)]="usuario.document"
                placeholder="digite o seu CPF" inputClass="loginTheme" mask="000.000.000-00">
              </app-input>
              <!-- <p>Confirme seu email, Caso não esteja correto, informe novamente o CPF.</p>
              <h3 class="mb-4">na**********os@fabsolucoes.com.br</h3> -->
            </div>
            <div class="d-flex justify-content-center">
              <app-button class="m" title="Voltar" text="Voltar"
              className="btn btn-block login-btn " [customStyle]="{'background': 'linear-gradient(33deg,
              rgba(255, 255, 255, 0.27) 30%,
              rgba(255, 255, 255, 0.01) 75%,
              rgba(255, 255, 255, 0.51) 100%)'}" (onClick)="goBack()">
            </app-button>
              <app-button [isDisabled]="isDocumentValid()"  class="m" title="Redefinir senha" text="Redefinir senha" [customStyle]="{'background': themeConfig.gradientColor}"
                className="btn btn-block login-btn mb" (onClick)="resetPassword()" >
              </app-button>
            
            </div>
            <div class="d-flex flex-column pt-1">
              <a href="javascript:" [ngStyle]="{'color': themeConfig.secondaryColor}" (click)="openDocuments()">
                Termos de uso e Política de privacidade
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>