import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { Location } from '@angular/common';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DropDownSettings, Select, UserInfo } from '../models/utils.model';
import { List, ListEnable, ListFormTypes } from '../models/lists.model';
import { FormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Unsubscribable } from 'rxjs';
import { BaseResponse } from './base.service';
import { City } from '../models/city.model';
import { LayoutConfig } from './theme.service';

export const noOption = 99;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  hourFormat = 'HH:mm';
  dateFormat = 'dd/MM/yyyy HH:mm:ss';
  dateYMDHS = 'YYYY-MM-DDTHH:mm:ss';
  formatHMS = 'HH:mm:ss';
  dateFormatDMY = 'DD/MM/YYYY';
  dateFormatdMy = 'dd/MM/yyyy';
  dateFormatDMTHM = 'DD/MM/YYYY HH:mm';
  dateComplete = 'YYYY-MM-DDTHH:mm:ss.sssZ';
  dateFormatYMD = 'YYYY-MM-DD';

  alfaNumericReg = /^[0-9][a-zA-Z]$/;
  rangeOfPositiveNumbers = '[0-9]*';
  currencyPattern = '^[0-9]+(.[0-9]+)?$';
  urlValidRegex =
    /^https?:\/\/(www\.)?([-\w]+\.)+[-\w]{2,}(\/[-\w\.]+)*\?(?:[\w\.]+\=[^&\?#]*)*(?:&[\w\.]+\=[^&\?#]*)*$/;

  timeToReload = 600;
  gmapKey = 'AIzaSyAF_3FSj6mOjsfMyguMBp3x2zMmXaAfwTM&q';

  constructor(
    protected http: HttpClient,
    private location: Location,
    protected toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private injector: Injector
  ) {}

  get<T>(args: string) {
    return this.http.get<T>(`${environment.urlApi}/${args}`).pipe(take(1));
  }

  post<T>(args: string, data) {
    return this.http
      .post<T>(`${environment.urlApi}/${args}`, data)
      .pipe(take(1));
  }

  put<T>(args: string, data) {
    return this.http
      .put<T>(`${environment.urlApi}/${args}`, data)
      .pipe(take(1));
  }

  patch<T>(args: string, data) {
    return this.http
      .patch<T>(`${environment.urlApi}/${args}`, data)
      .pipe(take(1));
  }

  delete<T>(id: any) {
    return this.http.delete<T>(`${environment.urlApi}/${id}`).pipe(take(1));
  }

  postPure<T>(args: string, data) {
    return this.http
      .post<T>(`${environment.urlApi}/${args}`, data)
      .pipe(take(1));
  }

  saveData(args: string, data, id: any) {
    let argsPut = args + '/' + id;
    if (id != 0) {
      return this.put(args, data);
    }
    return this.post(argsPut, data);
  }

  getMidEntities() {
    return this.http
      .get(`${environment.urlApi}/Middleware/CurrentUser/ShowEntities`)
      .pipe(take(1));
  }

  getErrorToastText(text: string) {
    return `Não foi possível carregar ${text}. Favor recarregar a tela.`;
  }

  /* Handle Local Storage */

  get entitiesLocalStorage(): Select[] {
    return JSON.parse(localStorage.getItem('entities'));
  }

  get tokenLocalStorage(): string {
    return localStorage.getItem('token');
  }

  get infoSistemicasLocalStorage(): string {
    return localStorage.getItem('infoSistemicas');
  }

  get infoFiscaisLocalStorage(): string {
    return localStorage.getItem('infoFiscais');
  }

  get infoOperacionaisLocalStorage(): string {
    return localStorage.getItem('infoOperacionais');
  }

  get tenantIdLocalStorage(): string {
    return localStorage.getItem('tenant_id');
  }

  get userInfoLocalStorage(): UserInfo {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  get permissionsLocalStorage(): string[] {
    return this.userInfoLocalStorage?.permissions || [];
  }

  get userNameSLocalStorage(): string {
    return localStorage.getItem('user');
  }

  get microcontrollerLocalStorage() {
    return JSON.parse(localStorage.getItem('search'));
  }

  get layoutConfigLocalStorage(): LayoutConfig {
    return JSON.parse(localStorage.getItem('layout-config'));
  }

  get userIdLocalStorage(): string {
    return localStorage.getItem('user_id');
  }

  setInfoFiscaisLocalStorage() {
    localStorage.setItem('infoFiscais', 'Informações Fiscais');
  }

  setInfoSistemicasLocalStorage() {
    localStorage.setItem('infoSistemicas', 'Informações Sistêmicas');
  }

  setInfoOperacionaisLocalStorage() {
    localStorage.setItem('infoOperacionais', 'Informações Operacionais');
  }

  setEntitiesLocalStorage(entities: string) {
    localStorage.setItem('entities', JSON.stringify(entities));
  }

  setTokenLocalStorage(token: string) {
    localStorage.setItem('token', token);
  }

  setUserInfoLocalStorage(userInfo: UserInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  setRolesLocalStorage(roles: string) {
    localStorage.setItem('roles', roles);
  }

  setTenantIdLocalStorage(tenantId: string) {
    localStorage.setItem('tenant_id', tenantId);
  }

  setUserIdLocalStorage(userId: string) {
    localStorage.setItem('user_id', userId);
  }

  setUserLocalStorage(userId: string) {
    localStorage.setItem('user', userId);
  }

  setMicroControllerLocalStorage(microcontroller) {
    localStorage.setItem(
      'search',
      JSON.stringify({ form: 'microController', data: microcontroller })
    );
  }

  setLayoutConfigLocalStorage(layoutConfig: LayoutConfig) {
    localStorage.setItem('layout-config', JSON.stringify(layoutConfig));
  }

  removeItemLocalStorage(item: string) {
    localStorage.removeItem(item);
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  /*  ------  */

  showErrorDialog(err: HttpErrorResponse) {
    if (
      err.error.status?.code == 401 ||
      err.error.status?.code == 404 ||
      err.error.status?.code == 400
    ) {
      const message = err?.error?.messages[0] ?? '';
      Swal.fire({
        title: 'Atenção',
        text: message.includes('format type')
          ? 'Este arquivo contém caractéres invalidos'
          : message,
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        timer: 3100,
      });
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Ops, não foi possível concluir a ação.',
        icon: 'error',
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  showHtmlDialog(title = '', text = '', icon: SweetAlertIcon = 'error', html) {
    return Swal.fire({
      icon: icon,
      title: title,
      text: text,
      html: text + html,
      confirmButtonColor: '#3085d6',
    });
  }

  showSuccessDialog(message: string, showConfirmButton = false, confirmButtonText = 'Ok', allowCloseOnActionOnly = false) {
    return Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: message,
      showConfirmButton: showConfirmButton,
      confirmButtonText: confirmButtonText,
      timer: showConfirmButton ? undefined : 2900, 
      allowOutsideClick: !allowCloseOnActionOnly, 
      allowEscapeKey: !allowCloseOnActionOnly, 
    });
  }

  showWarningDialog(message: string, showConfirmButton = false, timer = 2900) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: message,
      showConfirmButton: showConfirmButton,
      timer: timer,
      confirmButtonColor: '#3085d6',
    });
  }

  showConfirmationDialog(
    message: string,
    title = '',
    confirmButtonText = '',
    cancelButtonText = '',
    icon: SweetAlertIcon = 'warning'
  ) {
    return Swal.fire({
      title: title || 'Tem certeza?',
      text: message,
      icon: icon,
      confirmButtonText: confirmButtonText || 'Confirmar',
      cancelButtonText: cancelButtonText || 'Cancelar',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    });
  }

  showTextAreaDialog(
    message: string,
    inputLabel: string,
    validatorMessage: string,
    reverseButtons = false
  ) {
    return Swal.fire({
      title: 'Tem certeza?',
      text: message,
      icon: 'warning',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      input: 'textarea',
      inputLabel: inputLabel,
      inputValue: '',
      confirmButtonColor: '#3085d6',
      inputValidator: result => !result && validatorMessage,
      showCancelButton: true,
      reverseButtons: reverseButtons,
    });
  }

  showListDialog(
    icon: SweetAlertIcon,
    title: string,
    text: string,
    inputValue: number,
    list: Object
  ) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      input: 'select',
      inputValue: inputValue,
      inputOptions: list,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
    });
  }

  showSimpleDialog(
    text: string = '',
    confirmButtonText = 'Confirmar',
    denyButtonText = 'Cancelar',
    denyBtnColor = '#3085d6',
    confirmBtnColor = '#3085d6'
  ) {
    return Swal.fire({
      title: text,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: confirmButtonText,
      denyButtonText: denyButtonText,
      confirmButtonColor: confirmBtnColor,
      denyButtonColor: denyBtnColor,
    });
  }

  showToastDialog(text: string, icon: SweetAlertIcon = 'success', time = 4200) {
    Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: time,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    }).fire({
      icon: icon,
      title: text || '',
    });
  }

  print(data: string, openPDF: boolean = true, printAnex = false): void {
    if (!openPDF) {
      this.spinner.hide();
    }
    const url = `${environment.urlApi}/`;
    this.http.get(url + data, { responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (openPDF) {
          window.open(downloadLink.href);
        }

        this.spinner.hide();
      },
      err => {
        if (openPDF) {
          this.showWarningDialog(
            'Não foi possível processar o relatório solicitado.'
          );
        } else {
          this.toaster.error(
            'Não foi possível processar o relatório solicitado.'
          );
        }
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  printPost(data: string, value, runBackground = false): void {
    let url = `${environment.urlApi}/${data}`;
    if (runBackground) {
      url += `&runBackground=${runBackground}`;
    }
    this.http.post(url, value, { responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        if (!runBackground) {
          window.open(downloadLink.href);
        }
        this.spinner.hide();
      },
      err => {
        if (!runBackground) {
          this.showWarningDialog('Não há dados à serem impressos!');
        }
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  printGet(
    endpoint: string,
    warningMessage: string,
    errorMessage: string
  ): void {
    this.spinner.show();
    const url = `${environment.urlApi}/${endpoint}`;
    this.http.get(url, { responseType: 'blob' as 'json' }).subscribe(
      (response: Blob) => {
        if (response?.size) {
          this.spinner.hide();
          this.openFile(response);
        } else {
          this.spinner.hide();
          this.showWarningDialog(warningMessage);
        }
      },
      err => {
        console.error(err);
        this.spinner.hide();
        this.showWarningDialog(errorMessage);
      }
    );
  }

  openFile(response: Blob) {
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob([response], { type: response.type })
    );
    window.open(downloadLink.href);
  }

  findTextOfBoolean(list: ListEnable[], element: boolean) {
    return list?.filter(vl => vl.value == element)?.[0]?.text || '';
  }

  removeArrayElement(element: string, arrayList: string[]) {
    arrayList.forEach((id, index) => {
      if (id === element) arrayList.splice(index, 1);
    });
  }

  fixDate(date: string) {
    return date ? date.split('.')[0] : '';
  }

  formatToDateTimeLocal(date: string) {
    let splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T00:00`;
  }

  deleteFile(id: string) {
    return this.http.delete(`${environment.urlApi}/File/${id}`).pipe(take(1));
  }

  patchFile(data: FormData) {
    return this.http.patch(`${environment.urlApi}/File`, data).pipe(take(1));
  }

  viewFile(id: string) {
    return this.http
      .get(`${environment.urlApi}/File/${id}/view`, {
        responseType: 'blob' as 'json',
      })
      .pipe(take(1));
  }

  formatDate(date: string, format: string) {
    return moment(new Date(date)).format(format);
  }

  getUserLocaleDateFormat() {
    return moment().creationData().locale.longDateFormat('L');
  }

  formatDateYMD(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  formatDateYMDTHM(date: string) {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  }

  newDateYMDTHM() {
    return moment().format('YYYY-MM-DDTHH:mm');
  }

  newStartDateComplete() {
    return moment().minute(0).hour(0).format('YYYY-MM-DDTHH:mm:ss.sssZ');
  }

  newDateComplete() {
    return moment().format('YYYY-MM-DDTHH:mm:ss.sssZ');
  }

  getDayStartOrEnd(
    date: string,
    isStart = true,
    format = 'DD/MM/YYYY HH:mm:ss'
  ) {
    let nowMoment = moment(date);
    let start = date ? nowMoment?.startOf('day').format(format) : '';
    let end = date ? nowMoment?.endOf('day').format(format) : '';
    return isStart ? start : end;
  }

  newDatePickerStartDate(quantityDays: number, format: string) {
    return this.subtractDaysFromDate(
      this.newDateComplete(),
      quantityDays,
      format
    );
  }

  convertToCompleteDate(value: string) {
    if (value) {
      const date = value.split('/');
      const utc = `${'T'}${this.getCurrentOffset()}`;
      const completeDate = `${date[2] || ''}-${date[1] || ''}-${
        date[0] || ''
      }${utc}`;
      return completeDate;
    }
  }

  getCurrentOffset() {
    const dateUtc = moment(new Date()).utcOffset('UTC').format();
    return dateUtc?.split('T')?.[1] || '';
  }

  getUTCOffset(date: string) {
    const dateUtc = moment(date, 'DD-MM-YYYY HH:mm:ss')
      .utcOffset('UTC')
      .format();
    return dateUtc?.split('T')?.[1] || '00:00:00-03:00';
  }

  convertToShortDate(value: string) {
    if (value) {
      const [year, month, day] = value.split('T')[0]?.split('-');
      return `${day || ''}/${month || ''}/${year || ''}`;
    }
  }

  formatMomentDate(date: string | Date, fromFormat: string, toFormat: string) {
    return moment(date, fromFormat, true).format(toFormat);
  }

  // TODO: use a library
  getTimeDiff(date: string | Date) {
    const inputDate = moment(date)
    const now = moment()
    
    const minutesDifference = now.diff(inputDate, 'minutes')
    const hoursDifference = now.diff(inputDate, 'hours')
    const daysDifference = now.diff(inputDate, 'days')

    if (minutesDifference < 2) {
      return `${minutesDifference} minuto`
    } else if (minutesDifference < 60) {
      return `${minutesDifference} minutos`
    } else if (hoursDifference < 2) {
      return `${hoursDifference} hora`
    } else if (hoursDifference < 24) {
      return `${hoursDifference} horas`
    } else if (daysDifference == 1) {
      return `${daysDifference} dia`
    } else if (daysDifference >= 2) {
      return `${daysDifference} dias`
    }
  }

  datesDaysDiff(startDate: string, endDate: string) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'day');
  }

  datesMonthDiff(startDate: string, endDate: string) {
    const countJanuaryAsMonthNumberOne = 1;
    const start =
      moment(startDate, this.dateFormatYMD).get('month') +
      countJanuaryAsMonthNumberOne;
    const end =
      moment(endDate, this.dateFormatYMD).get('month') +
      countJanuaryAsMonthNumberOne;
    return start - end;
  }

  setMomentBrLocation() {
    moment.locale('pt-br');
  }

  isValidDate(date: string) {
    return moment(new Date(date)).isValid();
  }

  addDay(date: string, quantity: number, format: string) {
    return moment(date).add(quantity, 'days').format(format);
  }

  formatPatchFormDate(field: string, format: string, form: FormGroup) {
    const date = form?.get([field])?.value;
    form?.patchValue({
      [field]: this.formatDate(date, format)?.toString() || null,
    });
  }

  subtractDaysFromDate(date: string, quantity: number, format: string) {
    return moment(date).subtract(quantity, 'days').format(format);
  }

  newInitDate() {
    let date = moment().subtract(24, 'hours');
    return this.formatDateYMDTHM(date.toString());
  }

  convertDateTimeZone(date: string, utc: string, dateFormat: string) {
    return moment(date).utc().utcOffset(utc).format(dateFormat);
  }

  addMonthToDate(date: string, quantity: number) {
    let newDate = moment(date).add(quantity, 'month');
    return this.formatDateYMDTHM(newDate.toString());
  }

  getValidDatePeriod(startDate: string, endDate: string): boolean {
    return !!(
      (startDate && !endDate) ||
      (!startDate && endDate) ||
      endDate < startDate
    );
  }

  getTodayPeriod(format = this.dateYMDHS) {
    const nowMoment = moment();
    const start = nowMoment?.startOf('day').format(format);
    const end = nowMoment?.endOf('day').format(format);

    return {
      start: start || '',
      end: end || '',
    };
  }

  getLastDayOfMonth(format = this.dateFormatYMD) {
    return moment().endOf('month').format(format);
  }

  get currentTime() {
    return moment().format(this.hourFormat);
  }

  isDateBeforeToday(date: string) {
    return moment(date)?.isBefore(moment());
  }

  isFirstDateBeforeSecondDate(firstDate: string, secondDate: string) {
    return moment(firstDate)?.isBefore(moment(secondDate));
  }

  ValidTouched(value: string, formBase: FormGroup) {
    return !formBase.get(value).valid && formBase.get(value).touched;
  }

  updateControl(field: string, formBase: FormGroup) {
    formBase.controls[field]?.updateValueAndValidity();
  }

  setControlRequiredValidator(field: string, formBase: FormGroup) {
    formBase.controls[field]?.setValidators(Validators.required);
  }

  clearControlValidators(field: string, formBase: FormGroup) {
    formBase.controls[field]?.clearValidators();
  }

  applyCssErro(value: string, formBase: FormGroup) {
    return {
      'hass-error': this.ValidTouched(value, formBase),
    };
  }

  currencyOptions(prefix = '') {
    return {
      prefix: prefix,
      thousands: '.',
      decimal: ',',
      align: 'left',
    };
  }

  compareStrings(nameOriginal: String, nameSearch: String) {
    return nameOriginal?.toLowerCase()?.indexOf(nameSearch?.toLowerCase()) >= 0;
  }

  verifyNumberKeyPress(event: KeyboardEvent) {
    let key = +event.key;
    return key >= 0 && key <= 9;
  }

  keyPressEvent(event: KeyboardEvent) {
    return event.key === 'Enter';
  }

  findElementId(list: Select[], name: string) {
    return (
      list?.filter(res => {
        return res.name == name;
      })?.[0]?.id || ''
    );
  }

  findElementIdByDescription(list: ListFormTypes[], description: string) {
    return list?.filter(res => res.description == description)?.[0]?.id;
  }

  findElementName(list: Select[], id: string) {
    return list?.filter(item => item.id == id)?.[0]?.name || '';
  }

  findElementText(list: List[], value: number) {
    return list?.find(type => type.value === value)?.text || '';
  }

  buildParams<T>(parametros: T, quantity: Number, allowsFalsyValue = []) {
    let params = '';
    Object.entries(parametros).forEach(e => {
      if (
        (e[1] && e[1] != '') ||
        (allowsFalsyValue.includes(e[0]) && e[1] === 0)
      ) {
        params += `&${e[0]}=${e[1]}`;
      }
    });
    if (quantity) {
      params += `&Limit=${quantity}`;
    }
    return params;
  }

  buildArrStringParam<T>(elements: T[], field: string) {
    let stringParam = '';
    elements?.forEach(element => (stringParam += `&${field}=${element}`));
    return stringParam;
  }

  removeEmptyFields<T>(parametros: T) {
    let params = {};
    Object.entries(parametros).forEach(e => {
      if (e[1] && e[1] != '') {
        Object.assign(params, {
          [e[0]]: e[1],
        });
      }
    });

    return params;
  }

  findTextOfValue(list: List[], element: number) {
    return list?.filter(vl => vl.value == element)?.[0]?.text || '';
  }

  buildDropDownSettings(
    prep: string,
    element: string,
    primaryKey = 'id',
    labelKey = 'name'
  ): DropDownSettings {
    return {
      labelKey: labelKey,
      primaryKey: primaryKey,
      tagToBody: false,
      position: 'bottom',
      singleSelection: false,
      classes: 'form-control',
      enableSearchFilter: true,
      selectAllText: 'Selecionar todos',
      unSelectAllText: 'Limpar Seleção',
      text: `Selecione ${prep} ${element}s`,
      noDataLabel: 'Nenhum registro encontrado',
      searchPlaceholderText: `Buscar por ${element}`,
      filterSelectAllText: 'Selecionar todos os resultados filtrados',
    };
  }

  queryParamsPatchValues(
    queryParams: Params,
    fields: string[],
    form: FormGroup,
    convertValue = false
  ) {
    for (const field of fields) {
      if (queryParams[field]) {
        form?.patchValue({
          [field]: convertValue ? +queryParams[field] : queryParams[field],
        });
      }
      if (queryParams[field] === '') {
        form?.patchValue({
          [field]: '',
        });
      }
    }
  }

  goBack(time = 2000) {
    setTimeout(() => {
      this.location.back();
    }, time);
  }

  reloadPage(time = 2000) {
    setTimeout(() => {
      location.reload();
    }, time);
  }

  slice<T>(arrayList: T[], page: number, pageSize: number) {
    return arrayList?.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
  }

  filterElementsDifIndex<T>(list: T[], index: number) {
    return list?.filter((element, elementIndex) => index != elementIndex);
  }

  get invalidDateMessage() {
    return 'É preciso informar valores válidos para o período inicial e final';
  }

  resetStringFormFields(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form?.patchValue({
        [field]: '',
      });
    });
  }

  resetFormField(fields: string[], form: FormGroup, value = null) {
    for (const field of fields) {
      form?.patchValue({
        [field]: value,
      });
    }
  }

  disableOrEnableFormFields(
    formBase: FormGroup,
    fields: string[],
    disable = true
  ) {
    for (const field of fields) {
      if (formBase.get(field)) {
        if (disable) {
          formBase.get(field)?.disable();
        } else {
          formBase.get(field)?.enable();
        }
      }
    }
  }

  /**
   * Essa função recebe uma array de objetos do tipo List
   * e coverte para um objeto utilizando o campo value como key e o campo text como valor.
   * Em caso de "duplicatas" no array, esses valores serão sobescritos.
   * @param list Lista com os valores e descrição
   * @returns Objeto
   */
  convertListToObject(list: List[]) {
    return list.reduce((prev, next) => {
      prev[`${next.value}`] = next.text;
      return prev;
    }, {});
  }

  unsubscribeAll(unsubscribeList: Unsubscribable[]) {
    unsubscribeList.forEach(unsub => unsub.unsubscribe());
  }

  getCityList(id: number | string) {
    return this.get<BaseResponse<City[]>>(`Address/Cities/${id}`).pipe(take(1));
  }

  hasPermission(permissionsList: string[], permission: string): boolean {
    return !!permissionsList.find(res => res === permission);
  }

  noPermission(permissionName: string) {
    return !this.hasPermission(this.permissionsLocalStorage, permissionName);
  }

  sortStringDataAsc<T>(data: T[], item: string) {
    return data?.sort((element, nextElement) =>
      element[item] > nextElement[item]
        ? 1
        : nextElement[item] > element[item]
        ? -1
        : 0
    );
  }

  sortStringDataDesc<T>(data: T[], item: string) {
    return data?.sort((element, nextElement) =>
      element[item] < nextElement[item]
        ? 1
        : nextElement[item] < element[item]
        ? -1
        : 0
    );
  }

  isUrlValid(url: string): boolean {
    return this.urlValidRegex.test(url);
  }
}
