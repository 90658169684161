export class IAuth {
    document: string;
    userCode: string;
}

export class userInfo {
    name: string;
    entities: UserEntity[];
}

export class UserEntity {
    id: string;
    name: string;
    permissions: any //@audit todo
}