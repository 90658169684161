import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  constructor(
    private utils: UtilsService
  ) { }

  ngOnInit(): void {
  }

  goBack(){
    this.utils.goBack(0);
  }

}
