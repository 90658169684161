import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationItem, NavigationItemInterface } from '../navigation';
import { DattaConfig } from '../../../../../app-config';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { FeatureService } from 'src/app/shared/services/features.service';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent implements OnInit, AfterViewInit {
  @Input() navCollapsed = true;
  @Output() onNavCollapsedMob = new EventEmitter();

  isDarkTheme: boolean;
  featureDarkMode = false;
  public dattaConfig: any;
  public navigation: NavigationItemInterface[];
  public prevDisabled: string;
  public nextDisabled: string;
  public contentWidth: number;
  public wrapperWidth: any;
  public scrollWidth: any;
  public windowWidth: number;

  @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
  @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

  constructor(
    public nav: NavigationItem,
    private zone: NgZone,
    private location: Location,
    private utils: UtilsService,
    public themeService: ThemeService,
    private _featureService: FeatureService
  ) {
    this.dattaConfig = DattaConfig.config;
    this.windowWidth = window.innerWidth;

    this.navigation = this.nav.get();
    this.setNavigationPermissions(this.navigation);

    this.prevDisabled = 'disabled';
    this.nextDisabled = '';
    this.scrollWidth = 0;
    this.contentWidth = 0;
  }

  setNavigationPermissions(navigation: NavigationItemInterface[]) {
    navigation?.forEach(groups => {
      this.verifyMenuGroups(groups);
    });
  }

  verifyMenuGroups(groupList: NavigationItemInterface) {
    groupList?.children?.forEach(group => {
      this.diableGroup(group);
    });
  }

  diableGroup(group: NavigationItemInterface) {
    const disabledGroups = this.disabledMenuGroups;
    disabledGroups?.forEach(groupId => {
      if (groupId === group.id) {
        group.disabled = true;
      }
    });
    this.verifyNavItens(group.children);
  }

  verifyNavItens(navItens: NavigationItemInterface[]) {
    navItens?.forEach(item => {
      this.disableNavItem(item);
    });
  }

  disableNavItem(item: NavigationItemInterface) {
    const disabledItems = this.disabledMenuItems;
    disabledItems?.forEach(itemId => {
      if (itemId === item.id) {
        item.disabled = true;
      }
    });
  }

  get disabledMenuItems(): string[] {
    if (!this.utils.infoSistemicasLocalStorage && this.utils.infoOperacionaisLocalStorage) {
      return this.nav.operationalDisabledItens;
    } else if (this.utils.infoSistemicasLocalStorage) {
      return [];
    } else {
      return this.nav.fiscalDisableditens;
    }
  }

  get disabledMenuGroups(): string[] {
    if (!this.utils.infoSistemicasLocalStorage && this.utils.infoOperacionaisLocalStorage) {
      return this.nav.operationalDisabledGroup;
    } else if (this.utils.infoSistemicasLocalStorage) {
      return [];
    } else {
      return this.nav.fiscalDisabledGroup;
    }
  }

  ngOnInit() {
    this._featureService.init().then(async () => {
      this.featureDarkMode = await this._featureService.get('featureDarkMode');
    });

    this.isDarkTheme = this.themeService.isDarkModeStorage;

    if (this.windowWidth < 992) {
      this.dattaConfig['layout'] = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        (document.querySelector('#nav-ps-datta') as HTMLElement).style.maxHeight = '100%';
      }, 500);
    }
  }

  ngAfterViewInit() {
    if (this.dattaConfig['layout'] === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > (this.contentWidth - this.wrapperWidth)) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = 'disabled';
    }
    this.nextDisabled = '';
    (document.querySelector('#side-nav-horizontal') as HTMLElement).style.marginLeft = '-' + this.scrollWidth + 'px';
  }

  fireLeave() {
    const sections = document.querySelectorAll('.pcoded-hasmenu');
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove('active');
      sections[i].classList.remove('pcoded-trigger');
    }

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      this.onNavCollapsedMob.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.dattaConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.dattaConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.dattaConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

  get toggleClass(): string {
    return this.navCollapsed ? "themeToggleCollapsed" : "themeToggle";
  }

  changeTheme(isDakMode: boolean) {
    this.isDarkTheme = isDakMode;
    this.themeService.changeTheme(isDakMode);
  }

  get backgroundColor() {
    if (this.themeService.isDarkModeStorage) {
      return this.themeService.darkColor;
    } else {
      return this.themeService.sessionSecondaryColor || this.themeService.defaultSecondaryColor;
    }
  }

}
