<div class=" header-logo" [style.background-color]="backgroundColor">
  <!-- <a [routerLink]="['/dashboard/default/']" class="b-brand"> -->
  <a [routerLink]="['/dashboard/management']" class="b-brand">
    <span class="b-title">
      <img [src]="themeService.sessionLogoImg" class="logo" alt="" /></span>
  </a>
  <a href="javascript:" class="mobile-menu header" [ngClass]="{ on: navCollapsed }" id="mobile-collapse"
    (click)="navCollapse()" [title]="iconTitle">
    <i [ngClass]="menuIcon"></i>
  </a>
</div>