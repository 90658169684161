import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../../layout/admin/navigation/navigation';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;
  title = "Login";

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: any;

  constructor(
    private _router: Router,
    public nav: NavigationItem,
    private titleService: Title,
    private themeService: ThemeService
  ) {
    this.navigation = this.nav.get();
    this.setBreadcrumb();
    this.type = 'theme2';
  }

  ngOnInit() {
    this.themeService.init().then(() => {
      this.title = this.themeService.getThemeConfigItem('title');
    });
  }

  setBreadcrumb() {
    let routerUrl: string;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = router.url;
        this.filterNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink: string) {
    let result: any;
    let title = this.title;

    const splitMarkLink = activeLink.split('?');
    const splitEditLink = activeLink.split('/edit');
    const splitEditarLink = activeLink.split('/editar');
    const splitDetailsLink = activeLink.split('/details');
    const splitDetalhesLink = activeLink.split('/detalhes');

    this.navigation.forEach(function (group) {
      if (group.type === 'item' && 'url' in group &&
        (group.url === splitEditLink[0] || group.url === splitEditarLink[0] || group.url === splitDetailsLink[0] || group.url === splitDetalhesLink[0] || group.url === splitMarkLink[0])
      ) {
        result = [
          {
            url: 'url' in group ? group.url : false,
            title: group.title,
            breadcrumbs: 'breadcrumbs' in group ? group.breadcrumbs : true,
            type: group.type
          }
        ];
        title = group.title;
      } else {
        if (group.type === 'group' && 'children' in group) {
          group.children.forEach(function (collapseItem) {
            if (collapseItem.type === 'item' && 'url' in collapseItem &&
              (collapseItem.url === splitEditLink[0] || collapseItem.url === splitEditarLink[0] || collapseItem.url === splitDetailsLink[0] || collapseItem.url === splitDetalhesLink[0] || collapseItem.url === splitMarkLink[0])
            ) {
              result = [
                {
                  url: 'url' in collapseItem ? collapseItem.url : false,
                  title: collapseItem.title,
                  breadcrumbs: 'breadcrumbs' in collapseItem ? collapseItem.breadcrumbs : true,
                  type: collapseItem.type
                }
              ];
              title = collapseItem.title;
            } else {
              if (collapseItem.type === 'collapse' && 'children' in collapseItem) {
                collapseItem.children.forEach(function (item) {
                  if (item.type === 'item' && 'url' in item &&
                    (item.url === splitEditLink[0] || item.url === splitEditarLink[0] || item.url === splitDetailsLink[0] || item.url === splitDetalhesLink[0] || item.url === splitMarkLink[0])
                  ) {
                    result = [
                      {
                        url: 'url' in collapseItem ? collapseItem.url : false,
                        title: collapseItem.title,
                        breadcrumbs: 'breadcrumbs' in collapseItem ? collapseItem.breadcrumbs : true,
                        type: collapseItem.type
                      },
                      {
                        url: 'url' in item ? item.url : false,
                        title: item.title,
                        breadcrumbs: 'breadcrumbs' in item ? item.breadcrumbs : true,
                        type: item.type
                      }
                    ];
                    title = item.title;
                  }
                });
              }
            }
          });
        }
      }
    });
    this.navigationList = result;
    this.titleService.setTitle(title);
  }
}
