import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IAuth } from 'src/app/shared/models/auth.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ForgotPasswordService } from 'src/app/shared/services/forgot-password.service';
import { ThemeConfig, ThemeService } from 'src/app/shared/services/theme.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  themeConfig: ThemeConfig = {
    title: "",
    backgroundImg: "",
    themeColor: "",
    primaryColor: "",
    secondaryColor: "",
    gradientColor: "",
    loginImg: "",
  };
  public usuario: IAuth = new IAuth();

  showPassword = false;

  constructor(
    private router: Router,
    private forgotService: ForgotPasswordService,
    private utils: UtilsService,
    private themeService: ThemeService,
    private _spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.usuario.document = '';
    this.themeService.init().then(() => {
      this.themeConfig = this.themeService.getThemeConfig();
    });
  }

  formatDocument(inputString: string) {
    const formatted = inputString?.replace('-', '');
    const result = formatted?.split('.')?.join('');
    return result || "";
  }

  openDocuments() {
    this.router.navigate(['documents']);
  }

  goBack() {
    this.utils.goBack(0);
  }
  isDocumentValid(): boolean {
    if (this.usuario.document.length === 14) {
      return false
    }
    return true
  }
  resetPassword() {
    this.forgotService.forgotPasswordByDocument(this.usuario.document)
  }
}

