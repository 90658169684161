import { AuthService } from './../../../../../shared/services/auth.service';
import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../app-config';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/shared/services/theme.service';

const SAVED_PRINT_STATUS = 50;

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck, AfterViewInit {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  entityName: string;
  data: { status: number; message: string }[] = [];
  userEntities;

  constructor(
    private route: Router,
    config: NgbDropdownConfig,
    private utils: UtilsService,
    private themeService: ThemeService,
    private authService: AuthService,
  ) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
    this.userEntities = this.utils.entitiesLocalStorage;
  }

  ngOnInit() {
    this.loadNotification();
    this.setEntityName();
  }

  ngAfterViewInit(): void {
    let title = document.getElementById('item');
    title?.style?.setProperty('--hover-color', this.primaryColor);
  }

  setEntityName() {
    this.entityName = this.userEntities?.find(entity => entity.id === this.utils.tenantIdLocalStorage)?.name || "";
  }

  loadNotification() {
    this.utils
      .get<{ status: number; message: string }[]>(
        `Order/GetNotificationsByUserId/${this.userId}`
      )
      .pipe(take(1))
      .subscribe(
        res => {
          this.data = res["content"]["data"];
        },
        err => {
          console.error(err);
        }
      );
  }

  onChatToggle(friend_id: boolean) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    return document.querySelector('body').classList.contains('datta-rtl');
  }

  fazerLogout() {
    this.authService.logout();
  }

  loadPage(status: number) {
    status === SAVED_PRINT_STATUS ? this.route.navigate(['dashboard/prints']) : this.route.navigate(['dashboard/order']);
  }

  setTenantId(entityId: string, name: string) {
    this.entityName = name;
    this.utils.setTenantIdLocalStorage(entityId);
    this.utils.showToastDialog(`Entidade alterada para ${name}`);
    this.themeService.setLayoutConfig();
    setTimeout(() => {
      location.reload();
    }, 1600);
  }

  get backgroundColor() {
    return this.themeService.sessionSecondaryColor || this.themeService.defaultSecondaryColor;
  }

  get primaryColor() {
    return this.themeService.sessionPrimaryColor || this.themeService.defaultPrimaryColor;
  }

  get userId(): string {
    return this.utils.userIdLocalStorage;
  }

  get userName(): string {
    return this.utils.userNameSLocalStorage;
  }

  openPerfil(isDetails = false) {
    const userId = this.userId;
    this.route.navigate([`dashboard/account/${isDetails ? "details" : "edit"}/${userId}`]);
  }

  openMessages() {
    this.utils.showWarningDialog("Função não disponível no momento.");
  }
}
