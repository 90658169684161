import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import { environment } from "src/environments/environment";
import { take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

export interface ThemeConfig {
    title: string;
    backgroundImg: string;
    themeColor: string;
    secondaryColor: string;
    gradientColor: string;
    primaryColor: string;
    loginImg: string;
}

export interface LayoutConfig {
    address: string;
    content: ThemeConfig;
    document: string;
    entityImage: string;
    name: string;
}

@Injectable({
    providedIn: "root",
})

export class ThemeService {
    lowOpacity = "75";
    highOpacity = "10";

    lightColor = "#fff";
    darkGray = "#DEE2E6";
    darkColor = "#191C24";
    menuDarkColor = "#101216";
    darkSecondColor = "#2b2d39";
    defaultPrimaryColor = "#45B6FE";
    defaultSecondaryColor = "#101B33";

    private themeConfig: ThemeConfig;
    styleElement: HTMLStyleElement;

    constructor(
        protected http: HttpClient,
        private utils: UtilsService
    ) { }

    get layoutConfigLocalStorage() {
        return this.utils.layoutConfigLocalStorage;
    }

    get sessionPrimaryColor(): string {
        return this.layoutConfigLocalStorage?.content?.primaryColor || "";
    }

    get sessionSecondaryColor(): string {
        return this.layoutConfigLocalStorage?.content?.secondaryColor || "";
    }

    get sessionLogoImg(): string {
        return this.layoutConfigLocalStorage?.entityImage || "";
    }

    get isDarkModeStorage(): boolean {
        return localStorage.getItem('isDarkMode') === "true";
    }

    get theme(): string {
        return this.isDarkModeStorage ? "dark-mode" : "light-mode";
    }

    setIsDarkModeStorage(isDarkMode: boolean) {
        localStorage.setItem('isDarkMode', (isDarkMode).toString());
    }

    setLayoutConfig() {
        const entityId = this.utils.tenantIdLocalStorage;
        this.http
            .get(`${environment.urlApi}/Middleware/entities/${entityId}/custom-data`)
            .pipe(take(1))
            .subscribe(
                res => {
                    this.utils.setLayoutConfigLocalStorage(res["content"].data);
                }, err => {
                    console.error(err);
                }
            )
    }

    loadThemeConfigJson(): Promise<ThemeConfig> {
        return this.http
            .get<ThemeConfig>(`assets/theme-config.json`)
            .toPromise();
    }

    appendStyleThemeElements() {
        const head = document.getElementsByTagName('head')[0];
        const css = this.cssElementsList;

        this.styleElement = document.createElement('style');
        this.styleElement.innerHTML = "";
        this.styleElement.appendChild(document.createTextNode(css));
        head.appendChild(this.styleElement);
    }

    changeStyleThemeElements() {
        this.removeStyleThemeElements();
        this.appendStyleThemeElements();
    }

    removeStyleThemeElements() {
        this.styleElement?.remove();
    }

    public async init() {
        const configs = await this.loadThemeConfigJson();
        this.setThemeConfig(configs);
    }

    getThemeConfigItem(key: string) {
        return this.themeConfig[key];
    }

    getThemeConfig() {
        return this.themeConfig;
    }

    private setThemeConfig(configs: ThemeConfig) {
        this.themeConfig = configs;
    }

    changeTheme(isDarkMode: boolean) {
        this.setIsDarkModeStorage(isDarkMode);
        this.changeStyleThemeElements();
    }

    get spinnerBgColor(): string {
        return this.isDarkModeStorage ? 'rgb(0 0 0 / 79%)' : 'rgb(255 255 255 / 79%)';
    }

    get cssElementsList(): string {
        return this.matElementsCss +
            this.generalCss +
            this.swalAlertCss +
            this.ngbPaginationCss +
            this.tableCss +
            this.shepherButtonCss
    }

    get swalAlertCss(): string {
        return `
            .swal2-popup {
                background: ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor} !important;
                color: ${this.isDarkModeStorage ? this.lightColor : this.darkColor}  !important;
            }
        `
    }

    get ngbPaginationCss(): string {
        return `
            ngb-pagination .page-item.disabled .page-link {
                background-color:
                ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor} !important;
                border-color: ${this.isDarkModeStorage ? "#000000" : this.darkGray} !important;
                color: res !important;
            }

            ngb-pagination .page-link:hover {
                background-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor} !important;
                border-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor} !important;
                color:
                ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor} !important;
            }
            
            ngb-pagination .page-item.active .page-link {
                color: ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor};
                background-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
                border-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
            }

            ngb-pagination .page-link {
                color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
                background-color: ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor};
                border-color: ${this.isDarkModeStorage ? "#000000" : this.darkGray};
            }
            
            .page-link:hover {
                color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
            }

            .page-link:focus {
                box-shadow: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
            }
        `
    }

    get tableCss(): string {
        return `
            .table-hover tbody tr:hover {
                background-color: 
                ${this.sessionPrimaryColor + this.highOpacity || this.defaultPrimaryColor + this.highOpacity};
            }

            .table-striped {
                tbody tr:nth-of-type(2n + 1) {
                background-color: 
                ${this.sessionPrimaryColor + this.highOpacity || this.defaultPrimaryColor + this.highOpacity};
            }
        `
    }

    get shepherButtonCss(): string {
        return `
            .shepherd-button {
                background-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
            }

            .shepherd-button:hover, .shepherd-button:not(:disabled):hover {
                background-color: ${this.sessionPrimaryColor || this.defaultPrimaryColor};
            }
        `
    }

    get matElementsCss(): string {
        return `
            .mat-input-element, 
            .matLabel,
            .mat-option,
            .mat-select-disabled .mat-select-value {
                color: ${this.isDarkModeStorage ? this.lightColor : 'black'} !important;
            }

            .mat-card,
            .mat-table,
            .mat-menu-panel,
            .mat-option,
            .mat-dialog-container,
            .mat-expansion-panel,
            .mat-autocomplete-panel
            {
                background: ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor} !important;
            }
        `
    }

    get generalCss(): string {
        return `
            .text-dark {
                color: ${this.isDarkModeStorage ? this.lightColor : "#343a40"} !important;
            }

            .pcoded-header .dropdown.drp-user.show:before  {
                color: ${this.isDarkModeStorage ? this.darkSecondColor : this.lightColor} !important;
            }
        `
    }

}