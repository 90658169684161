import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import { environment } from "src/environments/environment";
import { take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

export interface ThemeConfig {
    title: string;
    backgroundImg: string;
    themeColor: string;
    secondaryColor: string;
    gradientColor: string;
    primaryColor: string;
    loginImg: string;
}

export interface LayoutConfig {
    address: string;
    content: ThemeConfig;
    document: string;
    entityImage: string;
    name: string;
}

@Injectable({
    providedIn: "root",
})

export class ThemeService {
    lowOpacity = "75";
    highOpacity = "10";
    defaultPrimaryColor = "#45B6FE";
    defaultSecondaryColor = "#101B33";

    private themeConfig: ThemeConfig;

    constructor(
        protected http: HttpClient,
        private utils: UtilsService
    ) { }

    get layoutConfigLocalStorage() {
        return this.utils.layoutConfigLocalStorage;
    }

    get sessionPrimaryColor() {
        return this.layoutConfigLocalStorage?.content?.primaryColor || "";
    }

    get sessionSecondaryColor() {
        return this.layoutConfigLocalStorage?.content?.secondaryColor || "";
    }

    get sessionLogoImg() {
        return this.layoutConfigLocalStorage?.entityImage || "";
    }

    setPaginationComponentColor() {
        document.querySelectorAll('.ngbPagination').forEach(
            (element: any) => {
                if (element) {
                    element?.style?.setProperty
                        ('--ngb-color', this.sessionPrimaryColor || this.defaultPrimaryColor);
                }
            })
    }

    setTableHoverColor() {
        document.querySelectorAll('.table-hover').forEach(
            (element: any) => {
                if (element) {
                    element?.style?.
                        setProperty('--table-hover',
                            this.sessionPrimaryColor + this.highOpacity ||
                            this.defaultPrimaryColor + this.highOpacity
                        );
                }
            })
    }

    setMenuHoverComponentColor() {
        document.querySelectorAll('.menu').forEach(
            (element: any) => {
                if (element) {
                    element?.style?.
                        setProperty('--menu-hover',
                            this.sessionPrimaryColor + this.highOpacity ||
                            this.defaultPrimaryColor + this.highOpacity
                        );
                }
            })
    }

    setShepherdButtonColor() {
        document.querySelectorAll('.shepherd-button').forEach(
            (element: any) => {
                if (element) {
                    element?.style?.
                        setProperty('--shepherd-button-color',
                            this.sessionPrimaryColor ||
                            this.defaultPrimaryColor
                        );
                }
            })
    }

    setLayoutConfig() {
        const entityId = this.utils.tenantIdLocalStorage;
        this.http
            .get(`${environment.urlApi}/Middleware/entities/${entityId}/custom-data`)
            .pipe(take(1))
            .subscribe(
                res => {
                    this.utils.setLayoutConfigLocalStorage(res["content"].data);
                }, err => {
                    console.error(err);
                }
            )
    }

    loadThemeConfigJson(): Promise<ThemeConfig> {
        return this.http
            .get<ThemeConfig>(`assets/theme-config.json`)
            .toPromise();
    }

    public async init() {
        const configs = await this.loadThemeConfigJson();
        this.setThemeConfig(configs);
    }

    getThemeConfigItem(key: string) {
        return this.themeConfig[key];
    }

    getThemeConfig() {
        return this.themeConfig;
    }

    private setThemeConfig(configs: ThemeConfig) {
        this.themeConfig = configs;
    }
}