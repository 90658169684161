<div class="row">
  <div class="col-12">
    <div class="input-group width">
      <input type="text" class="form-control font-style" placeholder="Busca por unidade ou andar..." #searchInput
        [(ngModel)]="search" [matAutocomplete]="auto" (keyup.enter)="loadData()">
      <div class="input-group-append">
        <button type="button" class="btn btn-primary border" (click)="loadData()" [style.background-color]="backgroundColor">
          <i class="feather icon-search search-button"></i>
        </button>
      </div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='go($event.option.value)' [panelWidth]="845"
      class="scrollbar-info">
      <ng-container *ngIf="data" class="font-style">
        <div class="row w-100">
          <div class="col-7">
            <h6 class="text-start mt-1 title pl-4">Unidade </h6>
          </div>
          <div class="col-5">
            <h6 class="text-start mt-1 title pl-4">Andar</h6>
          </div>
        </div>
        <div class="row w-100" *ngFor="let option of data">
          <hr>
          <div class="col-7 height">
            <hr>
            <mat-option [value]="[option.entity.id, true]" class="font-style">
              {{option.entity.name}}
            </mat-option>
          </div>
          <div class="col-5 height">
            <hr>
            <mat-option *ngFor="let vl of option.floors" [value]="[vl.id, false]" class="font-style">
              {{vl.name}}
            </mat-option>
          </div>
          <hr>
        </div>
        <hr>
      </ng-container>
    </mat-autocomplete>
    <ngx-spinner name="search" [fullScreen]="false" [zIndex]="9999" [color]="backgroundColor" bdColor="#FFFFFF"
      type="ball-clip-rotate-multiple" size="small"></ngx-spinner>
  </div>
</div>